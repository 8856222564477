/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useMemo } from "react";
import { likeQuestion } from "../services/vamaApi";
import ago from "s-ago";

const SubmittedQuestion = ({vama_id, question, showTranscript}) => {
    const [isPlaying, togglePlayback] = useState(false);
    const [isLiked, setIsLiked] = useState(question.isLiked);
    const [numLikes, setNumLikes] = useState(question.likes);

    const audioRef = useRef(new Audio(question.audio_url));
    
    const endPlayback = () => {
        togglePlayback(false);
    }

    const handlePlaybackClick = (e) => {
        if (!isPlaying) {
            let promise = audioRef.current.play();
            if (promise !== undefined) {
                promise.then(_ => {
                    // yay, playing
                }).catch(error => {
                    console.log(error);
                });
            }
        } else {
            audioRef.current.pause();
        }
        togglePlayback(!isPlaying);
    };

    const handleLike = (event) => {
        event.preventDefault();

        if (question.isLiked) {
            // TODO: handle unlike
            console.log('already liked');
            return;
        }

        setIsLiked(true);
        setNumLikes(numLikes + 1);

        // fire and forget
        likeQuestion(vama_id, question.question_id);

        console.log('like');
    };

    const handleTagClick = (event) => {
        event.preventDefault();
        console.log('tag click');
    };

    //const tags = useMemo(() => { question.tags.split('__').map(x => <li><a href="/#">{'#' + x}</a></li>) }, [question.tags]);
    const tags = question.tags ? question.tags.split('__').map(x => <li key={x}><a href="" onClick={handleTagClick}>{'#' + x}</a></li>) : "";

    if (question && question.answer && !Array.isArray(question.answer)) {
        question.answer = question.answer.timestamp ? [question.answer] : [];
    }

    useEffect(() => {
        audioRef.current.addEventListener("ended", endPlayback);

        return () => {
            // clean up audio player //
            audioRef.current.pause();
            audioRef.current.removeEventListener("ended", endPlayback);
        }
    }, []);

    return (
        <div>
            <div className={question.answer.length > 0 && question.answer[0].timestamp ? "vama-question-playback answered" : "vama-question-playback"}>
                <button onClick={handlePlaybackClick} className="vama-player-btn vama-playback-btn" type="button">
                    {isPlaying ? <img src="/assets/btn-pause-lrg.svg" alt="Pause" /> : <img src="/assets/btn-play-lrg.svg" alt="Play" />}
                </button>
                <div>
                    <dl>
                        <dt>{question.asker_name}</dt>
                        <dd>{ago(new Date(question.timestamp))}</dd>
                    </dl>
                    <ul>
                        {tags}
                    </ul>
                </div>
                <a href="" onClick={handleLike} className={"vama-heart-btn" + (isLiked ? " liked" : "")}>{numLikes}</a>
            </div>
            { question.transcription && (showTranscript || isPlaying) && 
            <dl>
                <dt>Transcription</dt>
                <dd>{question.transcription}</dd>
            </dl> }
        </div>
    );
};

export default SubmittedQuestion;