/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { formatTime } from '../services/utils';

const Recorder = ({divId, onNewRecording, onRedo, addClassName, responseText}) => {
    const [isRecording, toggleRecording] = useState(false);
    const [theRecording, saveRecording] = useState(null);
    const [recordingProgress, setRecordingProgress] = useState(0);
    const [recordingTimerId, setRecordingTimerId] = useState(0);

    const [isPlaying, togglePlayback] = useState(false);
    const [playbackProgress, setPlaybackProgress] = useState(0);
    
    const [recordingLength, setRecordingLength] = useState(0);
    const [pinProgress, setPinProgress] = useState({'left' : '-4%'});

    const recorderRef = useRef(null);
    const audioRef = useRef(new Audio());
    
    const handleRecorderClick = (event) => {
        
        if (isRecording) {
            console.log('clear timer: ' + recordingTimerId);
            clearInterval(recordingTimerId);

            // Stop recording
            recorderRef.current.stop();
            // Remove “recording” icon from browser tab
            recorderRef.current.stream.getTracks().forEach(i => i.stop());
        } else {
            navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
                recorderRef.current = new MediaRecorder(stream);

                recorderRef.current.addEventListener('dataavailable', (e) => {
                    saveRecording(e.data);
                });

                recorderRef.current.addEventListener('start', () => {
                    let recordingStartTime = (new Date()).getTime();
                    setRecordingTimerId(setInterval(() => {
                        setRecordingProgress((new Date()).getTime() - recordingStartTime);
                    }, 250));
                    console.log('set timer: ' + recordingTimerId);
                });

                // Start recording
                recorderRef.current.start();
            });
        }

        toggleRecording(!isRecording);
    };

    const handlePlaybackClick = (e) => {
        if (!isPlaying) {
            let promise = audioRef.current.play();
            if (promise !== undefined) {
                promise.then(_ => {
                    // yay, playing
                }).catch(error => {
                    console.log(error);
                });
            }
        } else {
            audioRef.current.pause();
        }
        togglePlayback(!isPlaying);
    };

    const handleRewindClick = () => {
        saveRecording(null);
        setPlaybackProgress(0);
        onNewRecording({recording:null, duration:0});

        if (onRedo) {
            onRedo();
        }
    };

    const getProgressTime = () => {
        return (isRecording ? formatTime(recordingProgress / 1000) : formatTime(playbackProgress));
    };

    // handle new recording //
    useEffect(() => {
        if (theRecording) {
            

            setRecordingProgress(0);
            audioRef.current = new Audio(URL.createObjectURL(theRecording));

            const setAudioTime = () => {
                setPlaybackProgress(audioRef.current.currentTime);
            }
            const getRecordingLength = () => {
                console.log('duration is: ' + audioRef.current.duration);

                // bubble up recording and duration
                onNewRecording({recording:theRecording, duration:audioRef.current.duration});

                setRecordingLength(audioRef.current.duration);
            }
            const endPlayback = () => {
                togglePlayback(false);
            }
            audioRef.current.addEventListener("timeupdate", setAudioTime);
            audioRef.current.addEventListener('loadedmetadata', getRecordingLength);
            audioRef.current.addEventListener("ended", endPlayback);
            
            return () => {
                audioRef.current.removeEventListener("timeupdate", setAudioTime);
                audioRef.current.removeEventListener('loadedmetadata', getRecordingLength);
                audioRef.current.removeEventListener("ended", endPlayback);
            }
        } else if (isPlaying) {
            audioRef.current.pause();
        }
    }, [theRecording]);

    useEffect(() => {
        if (isRecording && recordingProgress >= 0) {
            let offset = -4 + ((Math.min(30000, recordingProgress) / 30000) * 96);

            setPinProgress({'left' : offset + '%'});
        } else if (isPlaying && playbackProgress >= 0) {
            let offset = -4 + ((playbackProgress / recordingLength) * 96);

            setPinProgress({'left' : offset + '%'});
        } else {
            setPinProgress({'left' : '-4%'});
        }
    }, [recordingProgress, playbackProgress]);

    return (
        <div id={divId} className={"vama-audio-player" + (addClassName ? " " + addClassName : "")}>
            {responseText && <span>{responseText}</span>}
            <button onClick={handleRecorderClick} className="vama-player-btn vama-record-btn" type="button" disabled={(theRecording !== null)}>
                {isRecording ? <img src="/assets/btn-stop-sm.svg" alt="Stop" title="Stop" /> : <img src="/assets/btn-record-sm.svg" alt="Record" title="Record" />}
            </button>
            <span className="vama-current-time">{getProgressTime()}</span>
            <div className="vama-player-slider">
                <div className="vama-player-slider-pin" style={pinProgress}></div>
            </div>
            <button onClick={handlePlaybackClick} className="vama-player-btn vama-playback-btn" type="button" disabled={(theRecording === null)}>
                {isPlaying ? <img src="/assets/btn-pause-sm.svg" alt="Pause" title="Pause" /> : <img src="/assets/btn-play-sm.svg" alt="Preview" title="Preview" />}
            </button>
            <button onClick={handleRewindClick} className="vama-player-btn vama-rewind-btn" type="button" disabled={(theRecording === null)}>
                <img src="/assets/btn-rewind-sm.svg" alt="Redo" title="Redo" />
            </button>
        </div>
    );
};

export default Recorder;