/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { formatTime } from '../services/utils';

const Playback = ({audio_url}) => {
    const [isPlaying, togglePlayback] = useState(false);
    const [playbackProgress, setPlaybackProgress] = useState(0);
    
    const [playbackDuration, setPlaybackDuration] = useState(0);
    const [pinProgress, setPinProgress] = useState({'left' : '-5%'});

    const audioRef = useRef(new Audio(audio_url));

    const handlePlaybackClick = () => {
        if (!isPlaying) {
            let promise = audioRef.current.play();
            if (promise !== undefined) {
                promise.then(_ => {
                    // yay, playing
                }).catch(error => {
                    console.log(error);
                });
            }
        } else {
            audioRef.current.pause();
        }
        togglePlayback(!isPlaying);
    };

    const setAudioTime = () => {
        setPlaybackProgress(audioRef.current.currentTime);
    }
    const loadPlaybackDuration = () => {
        console.log('playback duration is: ' + audioRef.current.duration);

        setPlaybackDuration(audioRef.current.duration);
    }
    const endPlayback = () => {
        togglePlayback(false);
        setPlaybackProgress(0);
    }

    const getTime = (type) => {
        return (type === "progress" ? formatTime(playbackProgress) : formatTime(playbackDuration));
    };

    useEffect(() => {
        if (playbackProgress >= 0) {
            let offset = -5 + ((playbackProgress / playbackDuration) * 95);

            setPinProgress({'left' : offset + '%'});
        } else {
            setPinProgress({'left' : '-5%'});
        }
    }, [playbackProgress]);

    useEffect(() => {
        audioRef.current.addEventListener("timeupdate", setAudioTime);
        audioRef.current.addEventListener('loadedmetadata', loadPlaybackDuration);
        audioRef.current.addEventListener("ended", endPlayback);

        return () => {
            // clean up audio player //
            audioRef.current.pause();
            audioRef.current.removeEventListener("timeupdate", setAudioTime);
            audioRef.current.removeEventListener('loadedmetadata', loadPlaybackDuration);
            audioRef.current.removeEventListener("ended", endPlayback);
        }
    }, []);

    return (
        <div className="vama-audio-player">
            <button onClick={handlePlaybackClick} className="vama-player-btn vama-playback-btn">
                {isPlaying ? <img src="/assets/btn-pause-sm.svg" alt="Pause" /> : <img src="/assets/btn-play-sm.svg" alt="Play" />}
            </button>
            <span className="vama-current-time">{getTime("progress")}</span>
            <div className="vama-player-slider">
                <div className="vama-player-slider-pin" style={pinProgress}></div>
            </div>
            <span className="vama-total-time">{getTime("duration")}</span>
        </div>        
    );
};

export default Playback;