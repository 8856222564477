/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useMemo } from "react";
import { likeQuestion, unlikeQuestion } from "../services/vamaApi";
import { formatTime } from '../services/utils';
import ago from "s-ago";

const PublishedQuestionAnswer = ({vama_id, question, handleShowInfo, handleSetActive, handlePlayPauseClick, activeQuestionId, playingQuestionId, showTranscripts}) => {
    const [isLiked, setIsLiked] = useState(question.isLiked);
    const [numLikes, setNumLikes] = useState(question.likes);
    const [likeOnce, setLikeOnce] = useState(false);
        
    const isIntro = (question.question_id === 'intro');
    const isActive = isIntro ? false : (question.question_id === activeQuestionId || showTranscripts);
    const isPlaying = (question.question_id === playingQuestionId);

    if (question && question.answer && !Array.isArray(question.answer)) {
        question.answer = question.answer.timestamp ? [question.answer] : [];
    }

    let answerLength = 0;
    if (question.answer) {
        for (var j=0; j<question.answer.length; j++) {
            answerLength += Number(question.answer[j].audio_length);
        }
    }

    const handleLike = (event) => {
        event.preventDefault();

        if (isIntro || likeOnce) {
            return;
        }

        setLikeOnce(true);

        if (question.isLiked) {
            setIsLiked(false);
            setNumLikes(numLikes - 1);

            // fire and forget
            unlikeQuestion(vama_id, question.question_id);
        } else {
            setIsLiked(true);
            setNumLikes(numLikes + 1);

            // fire and forget
            likeQuestion(vama_id, question.question_id);
        }
    };

    return (
        <div className={isActive ? "expanded" : isPlaying ? "playing" : ""}>
            <div onClick={() => handleSetActive(question.question_id)}>
                <a href="" onClick={handleLike} className={"vama-heart-btn" + (isLiked ? " liked" : "")}>{numLikes}</a>
                <div>
                    <strong>{question.asker_name}</strong>
                    {!isIntro && <small>{ago(new Date(question.timestamp))}</small>}
                </div>
                {!isIntro && <dl>
                    <dt>Q:</dt>
                    <dd>{formatTime(question.audio_length)}</dd>
                    <dt>A:</dt>
                    <dd>{formatTime(answerLength)}</dd>
                </dl>}
            </div>
            {!isIntro && <p>{question.transcription}</p>}
            <div className="vama-secondary-btns">
                <img onClick={handleShowInfo} src="/assets/info.svg" className="vama-info-icon" alt="Info" title="Info"/>
                <button onClick={() => handlePlayPauseClick(question.question_id)} className="vama-player-btn vama-playback-btn">
                    {isPlaying ? <img src="/assets/btn-pause-sm.svg" alt="Pause" /> : <img src="/assets/btn-play-sm.svg" alt="Play" />}
                </button>
                <img onClick={() => handleSetActive(0)} alt="close" src="/assets/btn-transcript-toggle.svg" className="vama-transcript-toggle"/>
            </div>
        </div>
    );
};

export default PublishedQuestionAnswer;