/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import axios from 'axios';

const API_URL = 'https://www.vama.fm/vama-api';

export const useGetVama = ({vama_id = "", listen_id = ""}) => {
    const [vamaData, setVamaData] = useState(null);
    const [errors, setErrors] = useState({});

    const loadData = () => {
        axios.get(`${API_URL}?method=get-vama&vama_id=${vama_id}&listen_id=${listen_id}&user_id=${getUserId()}&_=${new Date().getTime()}`)
        .then(function (response) {
            setUserId(response);
            setVamaData(response.data);
        })
        .catch(function (error) {
            setErrors(error);
        });
    };

    return [
        loadData,
        vamaData,
        errors
    ];
}

export const useGetPublishedVama = ({vama_id}) => {
    const [vamaData, setVamaData] = useState(null);
    const [errors, setErrors] = useState({});

    const loadData = () => {
        axios.get(`${API_URL}?method=get-published-vama&vama_id=${vama_id}&user_id=${getUserId()}&_=${new Date().getTime()}`)
        .then(function (response) {
            setUserId(response);

            if (response.data.intro) {
                response.data.intro.question_id = 'intro';
                response.data.intro.asker_name = 'Intro';
                response.data.intro.isLiked = false;
                response.data.intro.likes = '-';
                response.data.questions.splice(0, 0, response.data.intro);
            }

            setVamaData(response.data);
        })
        .catch(function (error) {
            setErrors(error);
        });
    };

    return [
        loadData,
        vamaData,
        errors
    ];
}

export const getAudioUrl = async (blob, filename) => {
    let response = await axios.get(`${API_URL}?method=get-upload-url&filename=${filename}&_=${new Date().getTime()}`);
    
    const uploadURL = response.data.upload_url;
    const audioURL = response.data.audio_url;
    const options = { headers: { 'Content-Type': 'audio/wav', 'x-amz-acl': 'public-read' } };
    
    response = await axios.put(uploadURL, blob, options);

    console.log(response);

    return audioURL;
}

export const createVama = async (vama_name, creator_email, creator_name, answerer_name, answerer_email, prompt_text, prompt_blob) => {
    let prompt_url = '';
    if (prompt_blob) {
      prompt_url = await getAudioUrl(prompt_blob, 'instructions');
    }

    var params = [
      `user_id=${getUserId()}`,
      `creator_email=${encodeURIComponent(creator_email)}`,
      `creator_name=${encodeURIComponent(creator_name)}`,
      `vama_name=${encodeURIComponent(vama_name)}`,
      `answerer_name=${encodeURIComponent(answerer_name)}`,
      `answerer_email=${encodeURIComponent(answerer_email)}`,
      `prompt_text=${encodeURIComponent(prompt_text)}`,
      `prompt_url=${encodeURIComponent(prompt_url)}`,
      `_=${new Date().getTime()}`
    ];

    let response = await axios.get(`${API_URL}?method=create&${params.join('&')}`);

    setUserId(response);

    return response;
}

export const submitQuestion = async (vama_id, asker_name, asker_email, tags, ask_mode, question_text, question_blob, question_duration) => {
    const audio_url = await getAudioUrl(question_blob, vama_id);
    
    var params = [
      `user_id=${getUserId()}`,
      `vama_id=${vama_id}`,
      `asker_name=${encodeURIComponent(asker_name)}`,
      `asker_email=${encodeURIComponent(asker_email)}`,
      `question_tags=${encodeURIComponent(tags)}`,
      `_=${new Date().getTime()}`
    ];

    if (ask_mode === 'Text') {
        params.push(`question_text=${encodeURIComponent(question_text)}`);
    } else {
        params.push(`audio_url=${encodeURIComponent(audio_url)}`);
        params.push(`audio_length=${question_duration}`);
    }

    let response = await axios.get(`${API_URL}?method=ask&${params.join('&')}`);

    setUserId(response);

    return response;
}

export const saveAnswer = async (vama_id, listen_id, question_id, asker_name, asker_email, 
    answerer_name, answerer_email, answer_blob, answer_duration, answer_timestamp) => {
    const audio_url = await getAudioUrl(answer_blob, vama_id);
    
    var params = [
      `user_id=${getUserId()}`,
      `vama_id=${vama_id}`,
      `listen_id=${listen_id}`,
      `question_id=${question_id}`,
      `asker_name=${encodeURIComponent(asker_name)}`,
      `asker_email=${encodeURIComponent(asker_email)}`,
      `answerer_name=${encodeURIComponent(answerer_name)}`,
      `answerer_email=${encodeURIComponent(answerer_email)}`,
      `audio_url=${encodeURIComponent(audio_url)}`,
      `audio_length=${answer_duration}`,
      `answer_timestamp=${answer_timestamp}`,
      `_=${new Date().getTime()}`
    ];

    let response = await axios.get(`${API_URL}?method=answer&${params.join('&')}`);

    return response;
}

export const addIntro = async (vama_id, listen_id, intro_blob, intro_duration) => {
    const audio_url = await getAudioUrl(intro_blob, vama_id);
    
    var params = [
      `user_id=${getUserId()}`,
      `vama_id=${vama_id}`,
      `listen_id=${listen_id}`,
      `audio_url=${encodeURIComponent(audio_url)}`,
      `audio_length=${intro_duration}`,
      `_=${new Date().getTime()}`
    ];

    let response = await axios.get(`${API_URL}?method=intro&${params.join('&')}`);

    return response;
}

export const publishVama = async (listen_id) => {
    
    var params = [
      `user_id=${getUserId()}`,
      `listen_id=${listen_id}`,
      `_=${new Date().getTime()}`
    ];

    let response = await axios.get(`${API_URL}?method=publish&${params.join('&')}`);

    return response;
}

export const deleteQuestion = async (vama_id, question_id) => {
    var params = [
      `user_id=${getUserId()}`,
      `vama_id=${vama_id}`,
      `question_id=${question_id}`,
      `_=${new Date().getTime()}`
    ];

    let response = await axios.get(`${API_URL}?method=delete&${params.join('&')}`);

    return response;
}

export const likeQuestion = async (vama_id, question_id) => {
    var params = [
      `user_id=${getUserId()}`,
      `vama_id=${vama_id}`,
      `question_id=${question_id}`,
      `_=${new Date().getTime()}`
    ];

    let response = await axios.get(`${API_URL}?method=like&${params.join('&')}`);

    return response;
}

export const unlikeQuestion = async (vama_id, question_id) => {
    var params = [
      `user_id=${getUserId()}`,
      `vama_id=${vama_id}`,
      `question_id=${question_id}`,
      `_=${new Date().getTime()}`
    ];

    let response = await axios.get(`${API_URL}?method=unlike&${params.join('&')}`);

    return response;
}

const setUserId = (response) => {
    try {
        if (response && response.data && response.data.user_id) {
            window.localStorage.setItem('u', response.data.user_id);
        }
    } catch (_) {

    }
}

const getUserId = () => {
    try {
        return window.localStorage.getItem('u') ? window.localStorage.getItem('u') : "";
    } catch (_) { }
}