import React from "react";

const FooterCreate = () => {
    return (
        <div id="vama-footer-create">
            <h2>Ready for Another? Create a VAMA here.</h2>
            <a href="/create" className="vama-create-btn">Get Started</a>
        </div>
    );
};

export default FooterCreate;
