/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { saveAnswer, deleteQuestion } from "../services/vamaApi";
import { formatTime } from '../services/utils';

import ago from "s-ago";
import Recorder from "./Recorder";
import ErrorDialog from "../components/ErrorDialog";

const answer_timestamp = (new Date()).getTime();

const useSave = ({ vama_id, listen_id, question, name_field, email_field, recording, setErrorText }) => {
    const [isSaving, setIsSaving] = useState(false);
    const [answerSubmitted, setAnswerSubmitted] = useState(false);
    
    const handleSave = async (event) => {
        event.preventDefault();

        if (!name_field.current.value) {
            setErrorText('Please provide your name.');
            return;
        }

        if (!email_field.current.value) {
            setErrorText('Please provide your email.');
            return;
        }

        try {
            setIsSaving(true);

            let response = await saveAnswer(
                vama_id,
                listen_id,
                question.question_id, 
                question.asker_name,
                question.asker_email,
                name_field.current.value,
                email_field.current.value, 
                recording.recording,
                recording.duration,
                answer_timestamp);
            
            if (response.data === "question_answered") {
                setAnswerSubmitted(true);
            } else {
                setErrorText('something went wrong, try again.');
                console.err('something went wrong');
            }
        } catch (err) {
            setErrorText('something went wrong, try again.');
            //console.err(err);
        }
    }

    return [
        handleSave,
        isSaving,
        setIsSaving,
        setAnswerSubmitted,
        answerSubmitted
    ];
};

const useDelete = ({ vama_id, question_id }) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [isDeleted, setDeleted] = useState(false);
    
    const handleDelete = async () => {
        try {
            setIsDeleting(true);

            let response = await deleteQuestion(
                vama_id,
                question_id);
            
            if (response.data === "question_deleted") {
                setDeleted(true);
            } else {
                console.err('something went wrong');
            }
        } catch (err) {
          console.err(err);
        }
    }

    return [
        handleDelete,
        isDeleting,
        isDeleted
    ];
};

const QuestionAnswer = ({question, vama_id, listen_id, handleAnswerSubmitted}) => {
    const [isPlaying, togglePlayback] = useState(false);
    const [playbackProgress, setPlaybackProgress] = useState(0);
    const [playTimeLeft, setPlayTimeLeft] = useState(question.audio_length);
    const [pinProgress, setPinProgress] = useState({'left' : '-5%'});

    const [transcriptText, setTranscription] = useState(question.transcription);
    const [showTranscript, toggleShowTranscript] = useState(false);
    const [recordingData, setRecordingData] = useState({});

    const [isConfirmDelete, confirmDelete] = useState(false);
    //const [isDeleted, setDeleted] = useState(false);
    const [isResponding, setIsResponding] = useState(false);
    const [errorText, setErrorText] = useState('');

    const handleRespondClick = () => {
        setIsResponding(true);
    };

    const audioRef = useRef(new Audio(question.audio_url));
    const nameField = useRef(null);
    const emailField = useRef(null);

    const endPlayback = () => {
        togglePlayback(false);
        setPlayTimeLeft(question.audio_length);
        setPinProgress({'left' : '95%'});
    }

    const handlePlaybackClick = (e) => {
        if (!isPlaying) {
            let promise = audioRef.current.play();
            if (promise !== undefined) {
                promise.then(_ => {
                    // yay, playing
                }).catch(error => {
                    console.log(error);
                });
            }
        } else {
            audioRef.current.pause();
        }
        togglePlayback(!isPlaying);
    };

    const handleTranscriptClick = () => {
        toggleShowTranscript(!showTranscript);
    };

    const getTime = (type) => {
        return (type === "answer" ? formatTime(playTimeLeft) : formatTime(0))
    };

    const handleCopyTranscript = (event) => {
        event.preventDefault();
        navigator.clipboard.writeText(question.transcription);
        setTranscription('Copied to clipboard.');
        setTimeout(() => {
            setTranscription(question.transcription);
        }, 1000);
    };

    const [handleDelete, isDeleting, isDeleted] = useDelete({ vama_id: vama_id, question_id: question.question_id});

    const handleDeleteClick = () => {
        confirmDelete(true);
    };
    const handleDeleteYes = () => {
        confirmDelete(false);
        handleDelete();
    };
    const handleDeleteNo = () => {
        confirmDelete(false);
    };

    const handleRedo = () => {
        setIsSaving(false);
        setAnswerSubmitted(false);
        console.log('redo');
    };

    const [handleSave, isSaving, setIsSaving, setAnswerSubmitted, answerSubmitted] = useSave({ 
        vama_id: vama_id, 
        listen_id: listen_id, 
        question: question, 
        name_field: nameField,
        email_field: emailField,
        recording: recordingData,
        setErrorText: setErrorText
    });

    const handleCloseError = (event) => {
        event.preventDefault();
        setErrorText('');
    };

    if (question && question.answer && !Array.isArray(question.answer)) {
        question.answer = question.answer.timestamp ? [question.answer] : [];
    }

    const isAnswered = (answerSubmitted || (question.answer && question.answer.length > 0));
    //const responseText = isAnswered ? <p>Response <small>— Recorded <span>{ago(question.answer[0].timestamp ? new Date(question.answer[0].timestamp) : new Date())}</span></small></p> : <p>Response</p>;
    const responseText = <p>Response</p>;

    useEffect(() => {
        if (answerSubmitted) {
            handleAnswerSubmitted();
        }
    }, [answerSubmitted]);

    useEffect(() => {
        if (playbackProgress >= 0) {
            let offset = -5 + ((playbackProgress / question.audio_length) * 95);

            setPinProgress({'left' : offset + '%'});
        } else {
            setPinProgress({'left' : '-5%'});
        }
    }, [playbackProgress]);

    useEffect(() => {
        const setAudioTime = () => {
            setPlayTimeLeft(Math.max(0, question.audio_length - audioRef.current.currentTime));
            setPlaybackProgress(audioRef.current.currentTime);
        }

        audioRef.current.addEventListener("timeupdate", setAudioTime);
        audioRef.current.addEventListener("ended", endPlayback);

        return () => {
            // clean up audio player //
            audioRef.current.pause();
            audioRef.current.removeEventListener("timeupdate", setAudioTime);
            audioRef.current.removeEventListener("ended", endPlayback);
        }
    }, []);

    return (
        <div className={isAnswered && !isDeleted ? "response-recorded" : ""}>
            {isDeleted || isDeleting ?
            <div className="vama-preview-response-deleted">
                <svg width="47" height="53" viewBox="0 0 47 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.67857 3.31252H14.2679L15.254 1.37678C15.4629 0.962935 15.7847 0.614821 16.1832 0.371596C16.5817 0.128372 17.041 -0.000313442 17.5096 1.81491e-05H29.5009C29.9684 -0.00175513 30.427 0.12645 30.824 0.369945C31.2211 0.61344 31.5406 0.962376 31.746 1.37678L32.7321 3.31252H45.3214C45.7666 3.31252 46.1936 3.48701 46.5084 3.79762C46.8232 4.10823 47 4.5295 47 4.96877V8.28127C47 8.72053 46.8232 9.1418 46.5084 9.45241C46.1936 9.76302 45.7666 9.93751 45.3214 9.93751H1.67857C1.23339 9.93751 0.806435 9.76302 0.491638 9.45241C0.176846 9.1418 0 8.72053 0 8.28127V4.96877C0 4.5295 0.176846 4.10823 0.491638 3.79762C0.806435 3.48701 1.23339 3.31252 1.67857 3.31252ZM41.4187 48.3418C41.3387 49.6033 40.7744 50.7872 39.8408 51.6526C38.9072 52.518 37.6745 52.9998 36.3935 53H10.6065C9.32551 52.9998 8.09276 52.518 7.15916 51.6526C6.22557 50.7872 5.66131 49.6033 5.58125 48.3418L3.35714 13.25H43.6429L41.4187 48.3418Z" fill="#5c5c5c"/>
                </svg>
                <p>{isDeleted ? `${question.asker_name}'s question has been deleted.` : `Deleting...`}</p>
            </div> :
            <dl>
                <div className="vama-like-count">{question.likes}</div>
                <dt>{question.asker_name}</dt>
                <dd>{ago(new Date(question.timestamp))}

                 {isAnswered && 
                    <div className="vama-responders">
                        <small className="vama-responders-count">{question.answer.length}</small>
                        <ol className="vama-responders-list">
                            {question.answer.map(answer => <li key={answer.timestamp}>{answer.answerer_name}</li>)}
                        </ol>
                    </div>}
                </dd>
            </dl>}
            {isConfirmDelete ? <div className="vama-preview-delete-response">
                <p>Are you sure you want to delete this question?</p>
                <button onClick={handleDeleteYes} className="vama-yes-btn">Yes</button>
                <button onClick={handleDeleteNo} className="vama-no-btn">No</button>
            </div> : (!isDeleted &&
            <React.Fragment>
                <div className="vama-audio-player">

                    {question.question_text ?
                        <dt><div className="vama-question-label">Question</div>
                            <p className="vama-text-question">{question.question_text}</p>
                        </dt>
                    :
                      <React.Fragment>
                        <button onClick={handlePlaybackClick} className="vama-player-btn vama-playback-btn">
                            {isPlaying ? <img src="/assets/btn-pause-sm.svg" alt="Pause" /> : <img src="/assets/btn-play-sm.svg" alt="Play" />}
                        </button>
                        <span className="vama-current-time">{getTime('answer')}</span>
                        <div className="vama-player-slider">
                            <div className="vama-player-slider-pin" style={pinProgress}></div>
                        </div>
                      </React.Fragment>
                    }
                    

                    <button onClick={handleRespondClick} className="vama-player-btn vama-respond-btn" disabled={isResponding}>
                        <img src="/assets/btn-respond-sm.svg" alt="Respond" title="Respond"/>
                    </button>
                    <button onClick={handleDeleteClick} className="vama-player-btn vama-delete-btn">
                        <img src="/assets/btn-delete-sm.svg" alt="Delete" title="Delete"/>
                    </button>
                </div>
                { !question.question_text && 
                <dl className={"vama-transcription" + (showTranscript ? "" : " inactive")}>
                    <dt><span onClick={handleTranscriptClick}>Transcription</span></dt>
                    <dd>
                        <span>{transcriptText}</span>
                        <a href={question.audio_url} target="_blank" download="audio">
                            <button className="vama-player-btn vama-download-btn"><img src="/assets/btn-download-sm.svg" alt="Download" title="Download"/></button>
                        </a>
                        <button onClick={handleCopyTranscript} className="vama-player-btn vama-copy-btn"><img src="/assets/btn-copy-sm.svg" alt="Copy" title="Copy"/></button>
                    </dd>
                </dl>
                }
                {isResponding && 
                <React.Fragment>
                    <Recorder onNewRecording={setRecordingData} onRedo={handleRedo} addClassName="vama-record-response" responseText={responseText} />
                    <div className="vama-response-contact-form">
                        <div>
                            <label htmlFor="vama-response-contact-name">Name</label>
                            <input ref={nameField} type="text" id="vama-response-contact-name" placeholder="Enter your name"/>
                        </div>
                        <div>
                            <label htmlFor="vama-response-contact-email">Email</label>
                            <input ref={emailField} type="email" id="vama-response-contact-email" placeholder="Enter your email"/>
                        </div>
                    </div>
                    {errorText && <ErrorDialog message={errorText} handleCloseError={handleCloseError} />}
                    <button onClick={handleSave} className="vama-submit-btn" type="submit" disabled={!recordingData.recording || isSaving}>{isSaving ? answerSubmitted ? "Saved!" : "Saving..." : "Save Response"}</button>
                </React.Fragment>}
            </React.Fragment>)}
        </div>
    );
};

export default QuestionAnswer;