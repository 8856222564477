import React from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";
import AudioRecorder from 'audio-recorder-polyfill';

import Home from "./pages/Home";
import About from "./pages/About";
import Create from "./pages/Create";
import Ask from "./pages/Ask";
import Answer from "./pages/Answer";
import Listen from "./pages/Listen";

import Header from "./components/Header";
import Footer from "./components/Footer";

window.MediaRecorder = AudioRecorder;

export default function App() {
  return (
    <BrowserRouter>
      <div>
        <Header />
        
        <Switch>
          <Route path="/listen">
            <ListenRoute />
          </Route>
          <Route path="/answer">
            <AnswerRoute />
          </Route>
          <Route path="/ask">
            <AskRoute />
          </Route>
          <Route path="/create/ready">
            <Create ready="true" />
          </Route>
          <Route path="/create">
            <Create ready="false" />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>

      <Footer/>
      </div>
    </BrowserRouter>
  );
}


function AskRoute() {
  let match = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route path={`${match.path}/submitted`}>
        <Ask submitted={true}/>
        </Route>
        <Route path={`${match.path}/:vamaId`}>
          <Ask />
        </Route>
        <Route path={match.path}>
          <h3>Please specify a VAMA Id.</h3>
        </Route>
      </Switch>
    </div>
  );
}


function AnswerRoute() {
  let match = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route path={`${match.path}/:listenId`}>
          <Answer />
        </Route>
        <Route path={match.path}>
          <h3>Please specify a Listen Id.</h3>
        </Route>
      </Switch>
    </div>
  );
}

function ListenRoute() {
  let match = useRouteMatch();

  return (
    <div>
      <h2>Ask Away</h2>

      <Switch>
        <Route path={`${match.path}/:vamaId`}>
          <Listen />
        </Route>
        <Route path={match.path}>
          <h3>Please specify a VAMA Id.</h3>
        </Route>
      </Switch>
    </div>
  );
}
