/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useLayoutEffect, useRef, Fragment } from "react";
import { useParams } from "react-router-dom";
import { useGetPublishedVama } from "../services/vamaApi";
import { formatTime } from '../services/utils';

import FAQ from "../components/FAQ";
import FooterCreate from "../components/FooterCreate";
import PublishedQuestionAnswer from "../components/PublishedQuestionAnswer";

const Listen = (props) => {
    let { vamaId } = useParams();
    const [loadData, vamaData, loadingError] = useGetPublishedVama({ vama_id: vamaId });
    const [showTranscripts, setShowTranscripts] = useState(false);

    const [activeQuestionId, setActiveQuestionId] = useState(0);
    const [playingQuestionId, setPlayingQuestionId] = useState(0);
    const [isPaused, setIsPaused] = useState(true);
    const [isStart, setIsStart] = useState(true);

    const [currentIndex, _setCurrentIndex] = useState(0);
    const [playbackProgress, setPlaybackProgress] = useState(0);
    const [pinProgress, setPinProgress] = useState({'left' : '-4%'});

    const masterAudioRef = useRef(new Audio());
    const currentIndexRef = useRef(currentIndex);

    let questions = vamaData ? vamaData.questions : [];

    const setCurrentIndex = x => {
        console.log('setCurrentIndex = %s', x);
        currentIndexRef.current = x; // keep updated
        _setCurrentIndex(x);
    };
    

    let audioArr = [];
    for (var i=0; i<questions.length; i++) {
        audioArr.push(questions[i].audio_url);

        if (Array.isArray(questions[i].answer)) {

            for (var j=0; j<questions[i].answer.length; j++) {
                audioArr.push(questions[i].answer[j].audio_url);
            }

        } else {
            if (questions[i].answer && questions[i].answer.audio_url) {
                audioArr.push(questions[i].answer.audio_url);
            }
        }
    }

    let questionAnswerer = questions.map(x => x.asker_name).join(', ');
    questionAnswerer = questionAnswerer.replace('Intro, ', '');

    let questionCount = (vamaData && vamaData.intro ? questions.length - 1 : questions.length);

    let answeredBy = vamaData ? vamaData.vama.answerer_name || vamaData.vama.creator_name : '';

    let multiAnswer = [];
    for (var i=0; i<questions.length; i++) {
        if (Array.isArray(questions[i].answer)) {
            for (var j=0; j<questions[i].answer.length; j++) {
                if (questions[i].answer[j].answerer_name) {
                    multiAnswer.push(questions[i].answer[j].answerer_name);
                }
            }
        }
    }
    if (multiAnswer.length > 0) {
        answeredBy = multiAnswer.join(', ');
    }

    useEffect(() => { 
        if (!vamaData) {
            loadData();
        } else {
            document.title = `VAMA.fm | ${vamaData.vama.vama_name}`;
            
            masterAudioRef.current.addEventListener("timeupdate", setAudioTime);
            masterAudioRef.current.addEventListener("ended", endPlayback);
            
            return () => {
                // clean up audio player //
                masterAudioRef.current.pause();
                masterAudioRef.current.removeEventListener("timeupdate", setAudioTime);
                masterAudioRef.current.removeEventListener("ended", endPlayback);
            }
        }
    }, [vamaData]);

    const getQuestionId = (audio_url) => {
        for (var i=0; i<questions.length; i++) {
            if (questions[i].audio_url === audio_url) {
                return questions[i].question_id;
            } else if (Array.isArray(questions[i].answer)) {

                for (var j=0; j<questions[i].answer.length; j++) {
                    if (questions[i].answer[j].audio_url === audio_url) {
                        return questions[i].question_id;
                    }
                }

            } else if (questions[i].answer && questions[i].answer.audio_url === audio_url) {
                return questions[i].question_id;
            }
        }
        return 0;
    };
    const getIndex = (question_id) => {
        for (var i=0; i<questions.length; i++) {
            if (questions[i].question_id === question_id) {
                return audioArr.indexOf(questions[i].audio_url);
            }
        }
        return -1;
    };
    const getAnswerIndex = (question_id) => {
        for (var i=0; i<questions.length; i++) {
            if (questions[i].question_id === question_id && questions[i].answer) {

                if (Array.isArray(questions[i].answer)) {

                    for (var j=0; j<questions[i].answer.length; j++) {
                        return audioArr.indexOf(questions[i].answer[j].audio_url);
                    }

                } else {
                    return audioArr.indexOf(questions[i].answer.audio_url);
                }

            }
        }
        return -1;
    };
    const getQuestion = (question_id) => {
        for (var i=0; i<questions.length; i++) {
            if (questions[i].question_id === question_id) {
                return questions[i];
            }
        }
        return null;
    };

    const handleSetActive = (id) => {
        setActiveQuestionId(id);
    };

    const handlePlayPauseClick = (id) => {
        if (id === 0) {
            setIsPaused(true);
            setPlayingQuestionId(0);
        } else {
            var idx = getIndex(id);
            var answerIdx = getAnswerIndex(id);

            //console.log(`questionIdx = ${idx}, answerIdx = ${answerIdx}, currentIdx = ${currentIndexRef.current}`);

            if (idx !== currentIndexRef.current && answerIdx !== currentIndexRef.current) {
                setIsStart(false);
                setIsPaused(false);
                setCurrentIndex(idx);
                setPlayingQuestionId(idx);
            } else {
                handlePlay();
            }
        }
    };

    const handlePlay = () => {
        if (isStart) {
            masterAudioRef.current.src = audioArr[0];
            let promise = masterAudioRef.current.play();
            if (promise !== undefined) {
                promise.then(_ => {
                    // yay, playing
                }).catch(error => {
                    console.log(error);
                });
            }
            setIsStart(false);
        } else if (isPaused) {
            masterAudioRef.current.play();
        } else {
            masterAudioRef.current.pause();
        }

        setIsPaused(!isPaused);
    };
    
    const handlePrev = () => {
        setCurrentIndex(currentIndexRef.current - 1);
        //setCurrentIndex(prevVal => prevVal - 1);
    };
    const handleNext = () => {
        setCurrentIndex(currentIndexRef.current + 1);
        //setCurrentIndex(prevVal => prevVal + 1);
    };

    const handleShowAllTranscripts = (event) => {
        event.preventDefault();

        setShowTranscripts(!showTranscripts);
    };

    useEffect(() => {
        if (currentIndex < 0 || currentIndex === audioArr.length) {
            setPlayingQuestionId(0);
            setIsPaused(true);
            return;
        }

        if (audioArr.length > 0) {
            masterAudioRef.current.src = audioArr[currentIndex];
            let promise = masterAudioRef.current.play();
            if (promise !== undefined) {
                promise.then(_ => {
                    // yay, playing
                }).catch(error => {
                    debugger;
                    console.log(error);
                });
            }

            setPlayingQuestionId(getQuestionId(audioArr[currentIndex]));

            setIsStart(false);
            setIsPaused(false);
        }
    }, [currentIndex]);

    const getTime = (type) => {
        return (type === "progress" ? formatTime(playbackProgress) : formatTime(masterAudioRef.current.duration));
    };
    useLayoutEffect(() => {
        if (playbackProgress >= 0) {
            let offset = -4 + ((playbackProgress / masterAudioRef.current.duration) * 96);

            setPinProgress({'left' : offset + '%'});
        } else {
            setPinProgress({'left' : '-4%'});
        }
    }, [playbackProgress]);

    const setAudioTime = () => {
        setPlaybackProgress(masterAudioRef.current.currentTime);
    };

    const endPlayback = () => {
        if (currentIndexRef.current < audioArr.length - 1) {
            handleNext();
        } else {
            //debugger;
            setIsPaused(true);
            setIsStart(true);
            setCurrentIndex(-1);
        }
        
        setPlaybackProgress(0);
    };

    const [showInfo, setShowInfo] = useState(false);
    const handleShowInfo = () => {
        setShowInfo(true);
    };
    const handleCloseInfo = (event) => {
        event.preventDefault();
        setShowInfo(false);
    };

    console.log('vamaData: %O', vamaData);
    //console.log('activeQuestionId = %s, playingQuestionId = %s, currentIndex = %d, isPaused = %s', activeQuestionId, playingQuestionId, currentIndex, isPaused);
    

    return (
        <div id="vama-share">
            {showInfo && 
                <div id="vama-button-help" className="vama-dialog">
                    <div>
                        <h2>Button Help?</h2>
                        <ul>
                            <li>
                                <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="27" cy="27" r="27" fill="#C174F2"/>
                                    <path d="M35.8625 28.6926C37.1084 27.9082 37.1084 26.092 35.8625 25.3076L24.2418 17.9916C22.91 17.1531 21.1763 18.1103 21.1763 19.6841V34.3161C21.1763 35.8899 22.91 36.8471 24.2418 36.0086L35.8625 28.6926Z" fill="#fff"/>
                                </svg>
                                Play question &amp; answer
                            </li>
                            <li>
                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <ellipse cx="12.5" cy="12.5" rx="12.5" ry="12" fill="#FD73A4"/>
                                    <line x1="7" y1="12.5" x2="19" y2="12.5" stroke="#fff" strokeWidth="2"/>
                                </svg>
                                Hide question transcript
                            </li>
                            <li>
                                <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="27" cy="27" r="27" fill="#C174F2"/>
                                    <path d="M19 38.4104V17.5896C19 17.2655 19.2531 17.0003 19.5625 17.0003H21.8125C22.1219 17.0003 22.375 17.2655 22.375 17.5896V26.2518L31.5391 17.3637C32.5047 16.524 34 17.2262 34 18.5717V37.4283C34 38.7738 32.5047 39.476 31.5391 38.6363L22.375 29.8022V38.4104C22.375 38.7345 22.1219 38.9997 21.8125 38.9997H19.5625C19.2531 38.9997 19 38.7345 19 38.4104Z" fill="#fff"/>
                                </svg>
                                Previous question &amp; answer
                            </li>
                            <li>
                                <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg" style={{"transform": "rotate(180deg)"}}>
                                    <circle cx="27" cy="27" r="27" fill="#C174F2"/>
                                    <path d="M19 38.4104V17.5896C19 17.2655 19.2531 17.0003 19.5625 17.0003H21.8125C22.1219 17.0003 22.375 17.2655 22.375 17.5896V26.2518L31.5391 17.3637C32.5047 16.524 34 17.2262 34 18.5717V37.4283C34 38.7738 32.5047 39.476 31.5391 38.6363L22.375 29.8022V38.4104C22.375 38.7345 22.1219 38.9997 21.8125 38.9997H19.5625C19.2531 38.9997 19 38.7345 19 38.4104Z" fill="#fff"/>
                                </svg>
                                Next question &amp; answer
                            </li>
                            <li>
                                <svg width="31" height="13" viewBox="0 0 31 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M31 0.32159V11.6784C31 11.8552 30.8144 11.9998 30.5875 11.9998H28.9375C28.7106 11.9998 28.525 11.8552 28.525 11.6784V6.95354L21.8047 11.8016C21.0966 12.2596 20 11.8766 20 11.1427V0.857289C20 0.123381 21.0966 -0.259644 21.8047 0.198379L28.525 5.01699V0.32159C28.525 0.144809 28.7106 0.000170062 28.9375 0.000170062H30.5875C30.8144 0.000170062 31 0.144809 31 0.32159Z" fill="#CC79FF" fillOpacity="0.28"/>
                                    <path d="M0.00629832 11.676L0.235682 0.321522C0.239253 0.144777 0.427761 0.00391791 0.654589 0.0085003L2.30425 0.0418268C2.53108 0.0464092 2.71375 0.194767 2.71018 0.371512L2.61474 5.09541L9.43161 0.384062C10.1488 -0.059564 11.2374 0.34553 11.2226 1.07929L11.0149 11.3626C11.0001 12.0964 9.89598 12.4572 9.19725 11.9849L2.57563 7.03157L2.48079 11.726C2.47722 11.9028 2.28871 12.0436 2.06189 12.039L0.412222 12.0057C0.185394 12.0011 0.00272772 11.8528 0.00629832 11.676Z" fill="#CC79FF" fillOpacity="0.28"/>
                                </svg>
                                Skip to previous/next answer
                            </li>
                            <li>
                                <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M16.0495 0.804911C16.9461 0.436076 17.9041 0.253399 18.8686 0.267315C19.8332 0.281232 20.7854 0.491469 21.671 0.886016C22.5564 1.28049 23.3579 1.85148 24.0295 2.56637C24.7013 3.28104 25.2307 4.12605 25.5865 5.05239C25.9425 5.97889 26.1183 6.96899 26.1039 7.96611C26.0895 8.96324 25.8852 9.94785 25.5027 10.8637C25.1202 11.7795 24.567 12.6085 23.8748 13.3036L13.7397 23.4828C13.2919 23.9325 12.5767 23.9222 12.1421 23.4598L2.30489 12.9924C0.948123 11.5487 0.202221 9.60641 0.231273 7.59283C0.260324 5.57925 1.06195 3.65931 2.4598 2.25536C3.85765 0.851422 5.73722 0.0784827 7.68503 0.106585C9.63283 0.134688 11.4893 0.96153 12.8461 2.40522L13.2388 2.82308L13.6432 2.41691C14.3352 1.7216 15.153 1.17371 16.0495 0.804911ZM18.8349 2.60291C18.1672 2.59328 17.504 2.71975 16.8832 2.9751C16.2625 3.23044 15.6964 3.60966 15.2173 4.0911L14.0018 5.31188C13.5541 5.76157 12.8388 5.75125 12.4043 5.28883L11.2247 4.03368C10.2853 3.03412 8.99993 2.46164 7.65133 2.44218C6.30273 2.42273 5.00137 2.95789 4.03354 3.92993C3.06572 4.90198 2.5107 6.23129 2.49058 7.62543C2.47047 9.01956 2.98691 10.3643 3.92629 11.3639L12.9766 20.994L22.301 11.629C22.7803 11.1478 23.1636 10.5736 23.4284 9.93954C23.6932 9.30549 23.8346 8.62383 23.8446 7.93352C23.8545 7.2432 23.7328 6.55775 23.4864 5.91632C23.24 5.27489 22.8737 4.69006 22.4085 4.19524C21.9434 3.70018 21.3881 3.30439 20.775 3.03125C20.162 2.7581 19.5027 2.61255 18.8349 2.60291Z" fill="#C4C4C4"/>
                                </svg>
                                Question upvotes
                            </li>
                        </ul>
                        <a href="" onClick={handleCloseInfo} className="vama-cancel-link">Close</a>
                    </div>
                </div>
            }
            <header>
                <svg width="137" height="152" viewBox="0 0 137 152" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="81.1718" height="81.1367" rx="8" transform="matrix(0.866213 0.499675 -0.500325 0.865838 40.7603 0)" fill="#a6f568"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M90.6963 69.7507C80.9961 69.7507 71.6932 73.5893 64.8341 80.4219C57.9751 87.2545 54.1217 96.5216 54.1217 106.184V133.51C54.1217 136.025 52.0748 138.064 49.5499 138.064C47.0249 138.064 44.978 136.025 44.978 133.51V106.184C44.978 94.1059 49.7948 82.5221 58.3686 73.9813C66.9424 65.4405 78.5711 60.6423 90.6963 60.6423C102.822 60.6423 114.45 65.4405 123.024 73.9813C131.598 82.5221 136.415 94.1059 136.415 106.184V133.51C136.415 136.025 134.368 138.064 131.843 138.064C129.318 138.064 127.271 136.025 127.271 133.51V106.184C127.271 96.5216 123.418 87.2545 116.558 80.4219C109.699 73.5893 100.397 69.7507 90.6963 69.7507Z" fill="#000"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M44.9775 114.837C44.9775 112.272 47.0244 110.192 49.5494 110.192H63.2649C66.9024 110.192 70.391 111.66 72.9632 114.274C75.5353 116.887 76.9803 120.432 76.9803 124.128V138.064C76.9803 141.76 75.5353 145.305 72.9632 147.918C70.391 150.531 66.9024 152 63.2649 152H58.693C55.0555 152 51.5669 150.531 48.9947 147.918C46.4226 145.305 44.9775 141.76 44.9775 138.064V114.837ZM54.1212 119.483V138.064C54.1212 139.296 54.6029 140.477 55.4603 141.349C56.3176 142.22 57.4805 142.709 58.693 142.709H63.2649C64.4774 142.709 65.6402 142.22 66.4976 141.349C67.355 140.477 67.8367 139.296 67.8367 138.064V124.128C67.8367 122.896 67.355 121.714 66.4976 120.843C65.6402 119.972 64.4774 119.483 63.2649 119.483H54.1212ZM118.127 119.483C116.914 119.483 115.751 119.972 114.894 120.843C114.037 121.714 113.555 122.896 113.555 124.128V138.064C113.555 139.296 114.037 140.477 114.894 141.349C115.751 142.22 116.914 142.709 118.127 142.709H122.699C123.911 142.709 125.074 142.22 125.931 141.349C126.789 140.477 127.27 139.296 127.27 138.064V119.483H118.127ZM108.429 114.274C111.001 111.66 114.489 110.192 118.127 110.192H131.842C134.367 110.192 136.414 112.272 136.414 114.837V138.064C136.414 141.76 134.969 145.305 132.397 147.918C129.825 150.531 126.336 152 122.699 152H118.127C114.489 152 111.001 150.531 108.429 147.918C105.856 145.305 104.411 141.76 104.411 138.064V124.128C104.411 120.432 105.856 116.887 108.429 114.274Z" fill="#000"/>
                </svg>
                <h1>Voice + Ask Me Anything
                = <strong>VAMA</strong></h1>
            </header>
            {vamaData &&
            <div id="vama-share-player-wrap">
                <div>
                    <dl>
                        <dt>{vamaData.vama.vama_name}</dt>
                        <dd>Created by <strong>{vamaData.vama.creator_name}</strong></dd>
                    </dl>
                </div>
                <div id="vama-share-player">
                    {((activeQuestionId !== 0 && activeQuestionId !== 'intro') || (playingQuestionId && playingQuestionId !== 'intro')) ? 
                    <Fragment>
                        <blockquote>{getQuestion(activeQuestionId || playingQuestionId).transcription}</blockquote>
                        <h2 className="active">{getQuestion(activeQuestionId || playingQuestionId).asker_name}</h2>
                    </Fragment>
                    :
                    <Fragment>
                        <div id="vama-share-intro">
                            <p>Q&amp;A Count: <strong>{questionCount}</strong></p>
                            <p>Answers by: <strong>{answeredBy}</strong></p>
                            <p>Questions submitted by: <strong>{questionAnswerer}</strong></p>
                        </div>
                        <h2>Select a Question</h2>
                    </Fragment>
                    }
                    <div className="vama-audio-player">
                        <span className="vama-current-time">{getTime("progress")}</span>
                        <div className="vama-player-slider">
                            <div className="vama-player-slider-pin" style={pinProgress}></div>
                        </div>
                        <span className="vama-total-time">{getTime("duration")}</span>
                    </div>
                    <div className="vama-audio-player">
                        <button className="vama-player-btn vama-prev-answer-btn" disabled="disabled">
                            <img src="/assets/btn-prev-sm.svg" alt="Previous Answer" title="Previous Answer"/>
                        </button>
                        <button onClick={handlePrev} className="vama-player-btn vama-previous-btn" disabled={currentIndex <= 0}>
                            <img src="/assets/btn-prev.svg" alt="Previous" title="Previous"/>
                        </button>
                        <button onClick={handlePlay} className="vama-player-btn vama-playback-btn">
                            {isPaused ? <img src="/assets/btn-play-sm.svg" alt="Play" title="Play"/> : <img src="/assets/btn-pause-sm.svg" alt="Pause" title="Pause"/>}
                        </button>
                        <button onClick={handleNext} className="vama-player-btn vama-next-btn" disabled={currentIndex >= audioArr.length-1}>
                            <img src="/assets/btn-next.svg" alt="Next" title="Next"/>
                        </button>
                        <button className="vama-player-btn vama-next-answer-btn" disabled="disabled">
                            <img src="/assets/btn-next-sm.svg" alt="Next Answer" title="Next Answer"/>
                        </button>
                    </div>
                    { /* duplicate to show fixed version on mobile; just add fixed className on scroll to reposition */ }
                    <div className="vama-audio-player fixed">
                        <button className="vama-player-btn vama-prev-answer-btn" disabled="disabled">
                            <img src="/assets/btn-prev-sm.svg" alt="Previous Answer" title="Previous Answer"/>
                        </button>
                        <button onClick={handlePrev} className="vama-player-btn vama-previous-btn" disabled={currentIndex <= 0}>
                            <img src="/assets/btn-prev.svg" alt="Previous" title="Previous"/>
                        </button>
                        <button onClick={handlePlay} className="vama-player-btn vama-playback-btn">
                            {isPaused ? <img src="/assets/btn-play-sm.svg" alt="Play" title="Play"/> : <img src="/assets/btn-pause-sm.svg" alt="Pause" title="Pause"/>}
                        </button>
                        <button onClick={handleNext} className="vama-player-btn vama-next-btn" disabled={currentIndex >= audioArr.length-1}>
                            <img src="/assets/btn-next.svg" alt="Next" title="Next"/>
                        </button>
                        <button className="vama-player-btn vama-next-answer-btn" disabled="disabled">
                            <img src="/assets/btn-next-sm.svg" alt="Next Answer" title="Next Answer"/>
                        </button>
                    </div>
                    <div id="vama-share-all-questions">
                        <h3>Questions Answered: <img onClick={handleShowInfo} src="/assets/info.svg" className="vama-info-icon" alt="Info" title="Info"/></h3>
                        <a className="vama-show-btn" href="" onClick={handleShowAllTranscripts}>Show All Transcriptions</a>
                        {questions.map(question => <PublishedQuestionAnswer key={question.question_id} question={question} vama_id={vamaData.vama.id} 
                             handleShowInfo={handleShowInfo} 
                             handleSetActive={handleSetActive} 
                             handlePlayPauseClick={handlePlayPauseClick}
                             activeQuestionId={activeQuestionId} 
                             showTranscripts={showTranscripts}
                             playingQuestionId={isPaused ? 0 : playingQuestionId} />) }
                    </div>
                </div>
            </div>}
            <FAQ />
            <FooterCreate />
        </div>
    );
};

export default Listen;