

export const formatTime = (num) => {
  num = isNaN(num) ? 0 : Number(num);
  const mins = Math.floor(num / 60);
  
  const remainingSecs = Math.round(num - (mins * 60));
  const secs = remainingSecs < 10 ? '0' + remainingSecs : remainingSecs;

  return `${mins}:${secs}`;
}