import React from "react";

const ErrorDialog = (props) => {
    return (
        <div id="vama-faq">
            <div>
                <svg width="207" height="194" viewBox="0 0 207 194" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M25.9399 143.392C25.9399 154.151 38.9483 159.539 46.5563 151.931L86.3353 112.152C91.0515 107.436 91.0515 99.7894 86.3352 95.0732L46.5562 55.2942C38.9483 47.6863 25.9399 53.0745 25.9399 63.8337L25.9399 143.392Z" fill="#ff9ff5" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M126.872 47.9885C98.9067 40.4954 70.1623 57.0909 62.6692 85.0558C55.176 113.021 71.7716 141.765 99.7365 149.258C127.701 156.751 156.446 140.156 163.939 112.191C171.432 84.2261 154.836 55.4817 126.872 47.9885ZM53.1912 82.5162C62.0869 49.3168 96.2118 29.6148 129.411 38.5105C162.611 47.4063 182.313 81.5311 173.417 114.731C164.521 147.93 130.396 167.632 97.1968 158.736C63.9974 149.84 44.2954 115.716 53.1912 82.5162Z" fill="#000" />
                    <path d="M101.958 77.6936C104.252 74.3416 107.658 71.9095 111.573 70.828C115.488 69.7465 119.659 70.0854 123.348 71.7847C127.037 73.484 130.005 76.434 131.727 80.1123C133.45 83.7905 133.815 87.9596 132.758 91.8812C129.79 102.956 111.694 104.042 111.694 104.042" stroke="#000" strokeWidth="13" strokeLinecap="round" strokeLinejoin="round" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M101.463 125.04C102.164 122.423 104.855 120.87 107.472 121.571L107.525 121.585C110.142 122.287 111.695 124.977 110.994 127.594C110.292 130.211 107.602 131.765 104.985 131.063L104.932 131.049C102.315 130.348 100.762 127.658 101.463 125.04Z" fill="#000" />
                </svg>
                <dl>
                    <dt>What Is VAMA?</dt>
                    <dd>
                        <p>Voice + Ask Me Anything.</p>
                        <p>Audio Q&amp;A made fun and simple for friends, family, and fans.</p>
                    </dd>
                    <dt>How It Works</dt>
                    <dd>
                        <ul>
                            <li>Set up a VAMA page to collect voice questions.</li>
                            <li>Friends, family, or fans submit and upvote questions.</li>
                            <li>You record responses whenever you have time.</li>
                            <li>VAMA magically stitches everything together onto a shareable page</li>
                        </ul>
                    </dd>
                </dl>
            </div>
        </div>
    );
};

export default ErrorDialog;