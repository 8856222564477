import React from "react";

import WhatIsVama from "../components/WhatIsVama";
import FooterCreate from "../components/FooterCreate";

const About = () => {
  document.title = 'VAMA.fm | About Us';

  return (
    <div id="vama-about">
        <WhatIsVama />
        <div id="vama-about-text">
            <h3>WHAT WE BELIEVE</h3>
            <p>A great voicemail can be as much fun as a video, without adding even more screen and camera time to our day.</p>
            <p>We love podcasts, but we also want to hear the voices of family and friends when we put our headphones on.</p>
            <p>We think it’s fun to explore the middle ground between texts, calls, podcasts, and voicemails.</p>
            <h3>WHO WE ARE</h3>
            <p>We are a small group that work in various product, design, and engineering roles in Los Angeles, CA.</p>
            <h3>ORIGIN STORY</h3>
            <p>We have been tinkering with audio projects for many years.</p>            
            <p>Often we experiment just for fun, like setting up recurring “private podcasts” amongst family and friends as a way to stay connected during quarantine life.</p>
            <p>One afternoon, someone we love brainstorming with suggested that we try to quickly collect voice messages for his wife’s birthday, which was the next day. Not much time!</p>
            <p>We scrambled to set up a temporary phone number and collected voicemails from over 25 friends. We manually stitched all of the audio together, resulting in a single 15 minute voicemail that felt a bit like a “birthday podcast.”</p>
            <p>After seeing her reaction (tears of joy!), we realized it was time to bring this concept to a wider audience.</p>
            <p>We decided to build a product to automate the entire process&mdash;from voicemail box setup to audio compilation, stitching, and publishing.</p>
            <p>Vama.fm was born.</p>
            <p>We hope you enjoy it!</p>
        </div>
        <FooterCreate />
	</div>
  );
};

export default About;