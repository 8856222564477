import React from "react";

const Footer = () => {

    const renderLinkItem = (href, target, img, alt) => (
        <a href={href} target={target ? (target) : ""} ><img src={img} alt={alt} /></a>
    );

    return (
        <footer>
            <ul>
                <li>{renderLinkItem("https://www.instagram.com/vama.fm/", "_blank", "/assets/outline-dark-instagram-48.png", "vama.fm @ Instagram")}</li>
                <li>{renderLinkItem("https://twitter.com/vamafm", "_blank", "/assets/outline-dark-twitter-48.png", "vamafm @ Twitter")}</li>
                <li>{renderLinkItem("mailto:chris@hearicle.com", "", "/assets/outline-dark-forwardtofriend-48.png", "Email")}</li>
            </ul>
            <p>Copyright &copy; 2021 Hearicle LLC</p>
            <p><a href="/privacy">Privacy Policy</a> &amp; <a href="/terms">Terms of Service</a></p>
            <p>Made with <span role="img" aria-label="headphones">&#127911;</span> in Los Angeles, CA</p>
        </footer>
    );
};

export default Footer;
